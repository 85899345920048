document.addEventListener('DOMContentLoaded', function () {
	objectFitImages();
	setUA();
	toggleGnav();
	hidePagetop();
	hdrHover();
	hdrHover02();
	hideFixLink();
	hdrClick();
	hdrClick02();
	smoothScroll();

})
window.addEventListener('load', function () {
	AOS.init({
		once: true,
		duration: 1000,
	});
})

window.addEventListener('scroll', function () {
});


function setUA() {
	//ua判定
	var ua = UAParser()
	var HTML = document.getElementsByTagName('html')[0];
	var uaString = {
		browserName: ua.browser.name.toLowerCase().replace(' ', '-'),
		browserVersion: ua.browser.major,
		browserEngine: ua.engine.name.toLowerCase().replace(' ', '-'),
		osName: ua.os.name.toLowerCase().replace(' ', '-'),
		type: (typeof ua.device.type !== 'undefined') ? ua.device.type.toLowerCase().replace(' ', '-') : 'laptop'
	}
	HTML.dataset.browser = uaString.browserName
	HTML.dataset.browserversion = uaString.browserVersion
	HTML.dataset.browserengine = uaString.browserEngine
	HTML.dataset.os = uaString.osName
	HTML.dataset.type = uaString.type
}
function smoothScroll() {
	$('a[href^="#"]').click(function () {
		// スクロールの速度
		var speed = 500; // ミリ秒
		// アンカーの値取得
		var href = $(this).attr("href");
		// 移動先を取得
		var target = $(href == "#" || href == "" ? 'html' : href);
		// 移動先を数値で取得
		var position = target.offset().top;
		// スムーススクロール
		$('body,html').animate({ scrollTop: position }, speed, 'swing');
		return false;
	});
}
function toggleGnav() {
	$('.js-menu').click(function () {
		$(this).toggleClass('is-active')
		$('.js-gnav').stop().fadeToggle('is-active')
		return false;
	})
}
function hidePagetop() {
	$(window).scroll(function () {
		if ($(window).scrollTop() < 200) {
			$('.js-pagetop').stop().fadeTo(200, 0, function () {
				$('.js-pagetop').hide();
			});
		} else {
			$('.js-pagetop').stop().fadeTo(300, 1);
		}
	}).scroll();

}
function hideFixLink() {
	$(window).scroll(function () {
		if ($(window).scrollTop() + $(window).height() > $(".l-ftr").offset().top) {
			$('.js-fixLink').stop().fadeTo(200, 0, function () {
				$('.js-fixLink').hide();
			});
		} else {
			$('.js-fixLink').stop().fadeTo(300, 1);
		}
	}).scroll();

}
// function mediaSwitch() {

// 	var windowWidth = $(window).width();
// 	var windowSm = 767;

// 	if (windowWidth <= windowSm) {
// 		//横幅768px以下（スマホ）に適用させるJavaScriptを記述
// 		hdrClick();
// 		hdrClick02();

// 	} else {

// 		//横幅768px以上（PC、タブレット）に適用させるJavaScriptを記述
// 		hdrHover();
// 		hdrHover02();
// 	}
// }

//スマホ用　navメニュークリック時
function hdrClick() {
	$('.js-click01').click(function () {
		if ($(window).width() <= 767) {
			$(this).parent('.listItem').children('.js-list01').stop().slideToggle();
			$(this).children('.arrow').toggleClass('is-active');
		}
		return false;
	})

}

function hdrClick02() {
	$('.js-click02').click(function () {
		if ($(window).width() <= 767) {
			$(this).parent('.secondItemLink').parent('.secondList__item').children('.js-list02').stop().slideToggle();
			$(this).toggleClass('is-active');
			return false;
		}
	})

}

function hdrHover() {
	$('.js-hover01').hover(function () {
		if ($(window).width() > 767) {
			$(this).children('.js-list01').stop().slideToggle();
		}

	}, function () {
		if ($(window).width() > 767) {
			$(this).children('.js-list01').stop().slideToggle();
		}
	})
}
function hdrHover02() {
	$('.js-hover02').hover(function () {
		if ($(window).width() > 767) {
		$(this).children('.js-list02').stop().fadeToggle();
		}
	}, function () {
		if ($(window).width() > 767) {
		$(this).children('.js-list02').stop().fadeToggle();
		}
	})
}
function toggleGnav() {
	$('.js-hamburger').click(function () {
		$(this).toggleClass('is-active')
		$('.js-menu').slideToggle('is-active')
		return false;
	})

}
